<template>
	<div>
		<div class="home_top">
			<div>
				<video muted autoplay loop id="videos"
					src="https://cdnjkxp.pinyinhui.cn/video/%E5%93%81%E9%A5%AE%E6%B1%87%E5%AE%98%E7%BD%91%E5%BF%AB%E9%97%AA.mp4"></video>
			</div>
			<div ref="topContent" class="flexcol top_content">
				<div>
					精准触达
				</div>
				<div>
					全面接触
				</div>
				<div>
					
				</div>
				<div ref="numberTop">
					品饮汇只做一件事——站渠道商立场，帮渠道商选品
				</div>
			</div>
		</div>
		<!-- 携手中国饮品走向世界 -->
		<div class="flexcol home_map_mo">
			<div class="title">
				携手中国饮品走向世界
			</div>
			<div class="texts">
				<p>品饮汇创立于2012年，总部设在四川成都，专注为饮料行业品牌商、经销商、零售商、供应链服务商等前沿的行业资讯、品牌传播与品牌招商服务及渠道商选品服务，目前已经成为国内饮料行业极具影响力、号召力、知名度的饮料招商平台。</p>
				<p>品饮汇以“陪伴中国饮料走向世界”为公司愿景，以基于媒体广告、会议会展、整合营销服务为载体，为饮料品牌提供一站式渠道招商营销推广服务。</p>
			</div>
			<div class="numbs">
				<div class="numbs_item" v-for="(item,index) in numLisr" :key="index">
					<div class="flexcol">
						<template>
							<div class="num" v-if="item.nums<=item.currentNumber">
								{{item.num}}
							</div>
							<div class="num" v-else>
								<div v-for="(nums,index) in item.numbers" :key="index">
									{{nums}}
								</div>
							</div>
						</template>
						<div class="border_line"></div>
						<div class="eng">
							{{item.eng}}
						</div>
						<div class="jieshao">
							{{item.jieshao}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 产品与服务 -->
		<div class="flexcol product_mo">
			<div class="title">
				品饮汇产品与服务
			</div>
			<ul>
				<li class="flexcol" v-for="(item,index) in serviceList" :key="index">
					<img :src="item.img" alt="">
					<div>{{item.title}}</div>
					<p>{{item.content}}</p>
				</li>
			</ul>
		</div>
		<!-- 案例展示 -->
		<div class="case_mo">
			<div class="case_title">
				案例展示
			</div>
			<div class="case_list">
				<div class="case_list_title">
					{{caseList[act_case].title}}
				</div>
				<div class="case_list_content">
					{{caseList[act_case].content}}
				</div>
				<img class="case_list_img" src="../../assets/case_icon.png" alt="">
			</div>
			<div class="case_line">
				<div class="case_item"  ref="caseItem"
					v-for="item in caseList" :key="item">
					{{item.title}}
				</div>
			</div>
		</div>
		<!-- 地址 -->
		<div class="address flexcol">
			<div class="title">
				地址
			</div>
			<div class="texts">
				四川省成都市锦江区泰和·国际财富中心7栋702号
			</div>
				
				<div class="maps">
					<baidu-map :center="center" :zoom="zoom" @ready="handler" style="height:100%" :scroll-wheel-zoom='true'
						v-loading='loadingMap'>
						<bm-view style="width: 100%; height:100%; flex: 1"></bm-view>
						<bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none">
						</bm-local-search>
					</baidu-map>
				</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			numLisr:{
				type:Array
			},
			serviceList:{
				type:Array
			},
			caseList:{
				type:Array
			}
		},
		data() {
			return{
				duration:1000,
				act_case:0,
				// 地图相关
				loadingMap: true,
				BMap: '',
				map: '',
				geoc: '',
				showMap: false,
				addressKeyword: '',
				pointLngLat: '',
				center: {
					lng: 104.113343,
					lat: 30.641075
				},
				zoom: 18,
			}
		},
		methods:{
			changeNum() {
				this.numLisr.forEach(item => {
					// 初始化数字数组
					item.numbers = item.currentNumber.toString().split('').map(Number);
					// 启动定时器
					item.intervalId = setInterval(() => {
						const increment = Math.ceil((item.nums - item.currentNumber) / (this.duration /
						10));
						item.currentNumber += increment;
						// 更新数字数组
						item.numbers = item.currentNumber.toString().split('').map(Number);
						if (item.currentNumber >= item.nums) {
							clearInterval(item.intervalId);
						}
					}, 10);
				})
			},
			// 案例展示自动滚动
			changeCase() {
				this.$refs.caseItem[this.act_case].style.borderTop = "1px solid #CB2339"
				this.$refs.caseItem[this.act_case].style.color = "#fff"
				this.timer = setInterval(() => {
					this.act_case++
					if (this.act_case > 2) {
						this.act_case = 0
					}
					this.$refs.caseItem.forEach((item, index) => {
						if (index == this.act_case) {
							this.$refs.caseItem[index].style.borderTop = "1px solid #CB2339"
							this.$refs.caseItem[index].style.color = "#fff"
						} else {
							this.$refs.caseItem[index].style.borderTop = "1px solid #fff"
							this.$refs.caseItem[index].style.color = "rgba(255, 255, 255, 0.70)"
						}
					})
				}, 1000)
			},
			// 地图初始化
			handler({
				BMap,
				map
			}) {
				// this.mapOpen()
				this.BMap = BMap
				this.map = map
				this.loadingMap = true
				// var geolocation = new BMap.Geolocation()
				this.geoc = new BMap.Geocoder() // 地址解析对象
				var $this = this
				$this.$set($this, 'pointLngLat', {
					lng: 104.113343,
					lat: 30.641075,
				})
				map.enableScrollWheelZoom(false) // 开启鼠标滚轮缩放,默认关闭
				$this.addPoint({
					BMap,
					map
				})
				$this.loadingMap = false
				// 调用百度地图api 中的获取当前位置接口
				// geolocation.getCurrentPosition(function(r) {
				// 	let myGeo = new BMap.Geocoder()
				// 	myGeo.getLocation(new BMap.Point(r.point.lng, r.point.lat), function(result) {
				// 		if (result) {
				// 			console.log(result,333);
				// 			$this.loadingMap = false
				// $this.$set($this, 'pointLngLat', {
				// 	lng: 104.113343,
				// 	lat: 30.641075,
				// })
				// 			map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放,默认关闭
				// 			$this.addPoint({
				// 				BMap,
				// 				map
				// 			})
				// 		}
				// 	})
				// })
			},
			// 添加点位
			addPoint({
				BMap,
				map
			}) {
				map.clearOverlays() 
				var myIcon = new BMap.Icon(require("../../assets/mapsico.png"), new BMap.Size(50,45));
				var point = new BMap.Point(this.pointLngLat.lng, this.pointLngLat.lat)
				let zoom = map.getZoom()
				console.log('zoom:' + zoom)
				setTimeout(() => {
					map.centerAndZoom(point, zoom)
				}, 0)
				var marker = new BMap.Marker(point,  {icon: myIcon}) // 创建标注
				map.addOverlay(marker) // 将标注添加到地图中
				
				var opts = {position: point, };
				var label = new BMap.Label('<div><p>联系电话：86-19102811607</p><p>地址：四川省成都市锦江区泰和·国际财富中心7栋702号</p></div>', opts);
				label.setStyle({
				color: '#fff',
				borderRadius: '5px',
				border:"0",
				background:"rgba(0,0,0,0.5)",
				padding: '2px 10px',
				marginTop: "0px"
				});
				map.addOverlay(label);
			},
			// 地图相关
			mapOpen() {
				this.top = this.getScrollTop()
				if (this.top) {
					this.setScrollTop(0)
				}
			},
			// 关闭地图后调用
			mapClose() {
				this.setScrollTop(this.top)
				this.top = 0
				this.showMap = false
			},
		},
		mounted() {
			this.changeNum()
			this.changeCase()
			setTimeout(() => {
				this.$refs.topContent.style.opacity = 1
			}, 1000)
		},
	}
</script>
<style lang="less">
	.maps {
			width: 200px;
			height: 200px;
		}
	
</style>
<style lang="less" scoped>
	
	.home_top {
		width: 100%;
		height: 210px;
		position: relative;
		padding: 0;
	
		.top_content {
			width: 100%;
			height: 100%;
			justify-content: center;
			position: absolute;
			color: #fff;
			z-index: 1;
			top: 0;
			box-sizing: border-box;
			opacity: 0;
			transition: all linear 2s;
			display: none;
			div {
				&:nth-of-type(1) {
					font-size: 6.875rem;
					font-weight: 900;
					color: rgba(0, 0, 0, 0);
					line-height: 10rem;
					-webkit-text-stroke: 1px #FFFFFF;
					letter-spacing: 6px;
				}
	
				&:nth-of-type(2) {
					font-size: 6.875rem;
					font-weight: 900;
					line-height: 10rem;
					margin-top: -2.75rem;
					letter-spacing: 6px;
				}
	
				&:nth-of-type(3) {
					width: 3.75rem;
					height: 2px;
					background: #FFFFFF;
					margin-top: 0.625rem;
				}
	
				&:nth-of-type(4) {
					font-size: 10px;
					font-weight: 700;
					line-height: 3.25rem;
					margin-top: 2.5rem;
				}
			}
		}
	
		video {
			width: 100%;
			height: 100%;
			object-fit: contain;
			border: none;
		}
	
		video:focus {
			outline: none;
		}
	
		video:after {
			border: none;
		}
	}
	.address{
		width: 100%;
		padding-top: 30px;
		box-sizing: border-box;
		padding-bottom: 30px;
		.title {
			font-size: 18px;
			font-weight: 700;
			color: #333333;
			line-height: 26px;
		}
			
		.texts {
			width: 345px;
			font-size: 14px;
			font-weight: 400;
			color: #666666;
			line-height: 20px;
			margin-top: 10px;
			margin-bottom: 20px;
		}
		.maps {
			width: 345px;
			height: 200px;
		}
	}
	.home_map_mo {
		background-image: url("../../assets/home_map.png");
		background-size: 100% 100%;
		width: 100%;
		height: 346px;
		padding-top: 14.8125rem;
		box-sizing: border-box;
		margin-top: -10px;
	
		.numbs {
			width: 345px;
			display: flex;
			justify-content: space-between;
	
			.lines {
				width: 0px;
				height: 12.5rem;
				opacity: 1;
				border: 1px solid #999999;
				margin-left: 8.125rem;
			}
	
			.numbs_item {
				display: flex;
				justify-content: space-between;
			}
	
			.num {
				font-size: 4rem;
				color: #333;
				font-weight: 700;
				animation: number-roll 0.5s ease-in-out;
				display: flex;
				align-items: center;
			}
	
			.eng {
				color: #666666;
				font-size: 1.25rem;
				margin-top: 0.625rem;
				margin-bottom: 0.625rem;
				font-weight: 700;
			}
	
			.jieshao {
				font-size: 1.5rem;
				color: #666;
				font-weight: 700;
			}
	
			.border_line {
				width: 8.1875rem;
				height: 0.625rem;
				background: #CB2339;
				margin: auto;
				margin-top: -0.625rem;
			}
		}
	
	
		.title {
			font-size: 18px;
			font-weight: 700;
			color: #333333;
			line-height: 26px;
		}
	
		.texts {
			width: 345px;
			font-size: 12px;
			font-weight: 400;
			color: #666666;
			line-height: 20px;
			margin-top: 10px;
			margin-bottom: 20px;
			p{
				text-align: left;
				text-indent:2em;
			}
		}
	}
	.product_mo{
		width: 100%;
		background-color: #25272A;
		padding: 20px 0 0;
		box-sizing: border-box;
		.title {
			font-size: 18px;
			font-weight: 700;
			color: #fff;
			line-height: 26px;
			margin-bottom: 20px;
		}
		li{
			margin-bottom: 30px;
			color: #fff;
			img{
				width: 180px;
				height: 180px;
			}
			div{
				font-size: 14px;
				font-weight: 700;
				line-height: 20px;
				margin: 20px 0 10px;
			}
			p{
				width: 300px;
				font-size: 12px;
				font-weight: 400;
				line-height: 17px;
			}
		}
	}
	.case_mo {
		width: 100%;
		height: 200px;
		background-image: url("../../assets/case_bg.png");
		background-size: 100% 100%;
		padding-top: 50px;
		padding-left: 30px;
		box-sizing: border-box;
	
		.case_title {
			font-size: 18px;
			font-weight: 700;
			color: #FFFFFF;
			line-height: 26px;
			// line-height: 72px;
		}
	
		.case_list {
			margin-top: 40px;
			margin-left: 60px;
			display: flex;
			margin-bottom: 6px;
	
			.case_list_title {
				font-size: 14px;
				font-weight: 700;
				color: #FFFFFF;
				line-height: 20px;
			}
	
			.case_list_content {
				width: 170px;
				font-size: 10px;
				color: #FFFFFF;
				line-height: 14px;
				margin-left: 13px;
				margin-right: 5px;
			}
	
			.case_list_img {
				width: 15px;
				height: 15px;
			}
		}
	
		.case_line {
			width: 240px;
			margin-top: 6px;
			margin-left: 60px;
			display: flex;
	
			.case_item {
				width: 33%;
				text-align: center;
				border-top: 1px solid #fff;
				padding-top: 6px;
				font-size: 10px;
				color: rgba(255, 255, 255, 0.70);
			}
	
			// .case_item:hover{
			// 	color: #fff;
			// 	border-top: 4px solid #CB2339;
			// }
		}
	}

</style>