<template>
	<div v-if="!ismobile">
		<div style="width: 100%;height: 5.625rem;"></div>
		<div class="fkex">
			<div v-html='info.main_wmnugi' class="rich_content"></div>
		</div>
	</div>
	<div v-else>
		<div style="width: 100%;height: 100px;"></div>
		<div class="fkex1">
			<div v-html='info.main_wmnugi' class="rich_content1"></div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				id:0,
				info:{},
				ismobile:false,
			};
		},
		methods: {
			getDetail(){
				this.$api.reviewDetail({
					id:this.id
				}).then(res=>{
					if(res.code==1){
						this.info=res.data
					}
				})
			}
		},
		mounted() {
			this.id=this.$route.query.id
			this.getDetail()
			this.ismobile=this.$isMobile()
		},
		beforeDestroy() {

		},
	};
</script>

<style lang="less" >
	.fkex{
		width: 800px;
		display: flex;
		align-items: center;
		flex-direction: column;
		margin:0 auto
	}
	.fkex1{
		display: flex;
		align-items: center;
		flex-direction: column;
		margin:0 auto;
		padding:0px 10px
	}
</style>

<style scoped>
	.rich_content1{
		white-space:pre-wrap;
		
	}
	.rich_content1>>> p{
		line-height: 20px;
	}
	.rich_content1>>> img{
		display: block;
		width:100%;
		height:auto
	}
	.rich_content{
		white-space:pre-wrap;
		font-size: 1.3rem;
	}
	.rich_content>>> p{
		line-height: 3rem;
	}
	.rich_content>>> img{
		display: block;
		/* max-width: 100%; */
		width:100%;
		height:auto
	}
</style>
