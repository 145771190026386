import ServiceApi from "./service";
let Service = ServiceApi.service;
// 所有的接口都要定义在该文件
export default {
	getList(params) {
		return Service.post("/cases/getList", params);
	},
	caseDetail(params) {
		return Service.post("/cases/getDetail", params);
	},
	reviewList(params) {
		return Service.post("/review/getList", params);
	},
	reviewDetail(params) {
		return Service.post("/review/getDetail", params);
	},
	
	
};
// 验证码
// captcha(params) {
// 	return Service.get("/api/user/captcha", params);
// }
