<template>
	<div>
		<template>
			<div class="home" v-if='!ismobile'>
				<div class="home_top">
					<div>
						<video muted autoplay loop id="videos"
							src="https://cdnjkxp.pinyinhui.cn/video/%E5%93%81%E9%A5%AE%E6%B1%87%E5%AE%98%E7%BD%91%E5%BF%AB%E9%97%AA.mp4"></video>
					</div>
					<div ref="topContent" class="flexcol top_content">
						<div>
							精准触达
						</div>
						<div>
							全面接触
						</div>
						<div>

						</div>
						<div ref="numberTop">
							品饮汇只做一件事——站渠道商立场，帮渠道商选品
						</div>
					</div>
				</div>
				<!-- 携手中国饮品走向世界 -->
				<div class="flexcol home_map">
					<div class="title">
						携手中国饮品走向世界
					</div>
					<div class="texts">
						<p>品饮汇创立于2012年，总部设在四川成都，专注为饮料行业品牌商、经销商、零售商、供应链服务商等前沿的行业资讯、品牌传播与品牌招商服务及渠道商选品服务，目前已经成为国内饮料行业极具影响力、号召力、知名度的饮料招商平台。
						</p>
						<p>品饮汇以“陪伴中国饮料走向世界”为公司愿景，以基于媒体广告、会议会展、整合营销服务为载体，为饮料品牌提供一站式渠道招商营销推广服务。</p>
					</div>
					<div class="numbs">
						<div class="numbs_item" v-for="(item,index) in numLisr" :key="index">
							<div class="flexcol">
								<template>
									<div class="num" v-if="item.nums<=item.currentNumber">
										{{item.num}}
									</div>
									<div class="num" v-else>
										<div v-for="(nums,index) in item.numbers" :key="index">
											{{nums}}
										</div>
									</div>
								</template>
								<div class="border_line"></div>
								<div class="eng">
									{{item.eng}}
								</div>
								<div class="jieshao">
									{{item.jieshao}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 产品服务 -->
				<div class="service">
					<div class="title" ref="titles" style="margin: auto;text-align: center;color: #fff;">
						品饮汇产品与服务
					</div>
					<div class="service_list">
						<div :class="index%2!=0?'service_saa':''" v-for="(item,index) in serviceList" :key="index">
							<div class="service_item" ref="serviceItem">
								<img :style="index%2!=0?{'margin-left':'3.25rem','margin-right':0}:''" :src="item.img"
									alt="">
								<div class="service_content">
									<div>
										{{item.title}}
									</div>
									<div>
										{{item.content}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 案例展示 -->
				<div class="case">
					<div class="case_title">
						品饮汇 案例展示
					</div>
					<div class="case_list">
						<div class="case_list_title">
							{{caseList[act_case].title}}
						</div>
						<div class="case_list_content">
							{{caseList[act_case].content}}
						</div>
						<img class="case_list_img" src="../assets/case_icon.png" alt="">
					</div>
					<div class="case_line">
						<div class="case_item" @mouseleave="changeCase()" @mouseenter="inCase(index)" ref="caseItem"
							v-for="(item,index) in caseList" :key="index">
							{{item.title}}
						</div>
					</div>
				</div>
				<div style="background: #f6f6f6;width: 100%;">
					<div class="news">
						<div class="news_title">
							最新动态
						</div>
						<div class="news_shows">
							<div class="lefts">
								<el-carousel height="224px">
									<el-carousel-item v-for="(item,index) in newslists" :key="index">
										<img class="banner" style="width: 100%;height: 100%;"
											:src="'https://crm.pinyinhui.cn'+item" alt="">
									</el-carousel-item>
								</el-carousel>


							</div>
							<div class="right">
								<span v-for="(item,index) in reviewList" :key="index" @click="toDetail(item)">
									<div class="one" v-if="index==0">
										<a href='javascript:;' :title="item['main_cutmol']">{{item['main_cutmol']}}</a>
									</div>
									<div class="fouver tetxs" v-else-if="index==reviewList.length-1">
										<a href='javascript:;' :title="item['main_cutmol']">{{item['main_cutmol']}}</a>
									</div>
									<div v-else class=" tetxs">
										<a href='javascript:;' :title="item['main_cutmol']">{{item['main_cutmol']}}</a>
									</div>

								</span>

							</div>
							<div style="clear: both;"></div>
						</div>
					</div>
				</div>

				<!-- 地址 -->
				<div class="sec1 flexcol">
					<div class="title">
						地址
					</div>
					<div class="texts">
						四川省成都市锦江区泰和·国际财富中心7栋702号
					</div>
					<div class="maps">
						<baidu-map :center="center" :zoom="zoom" @ready="handler" style="height:100%"
							:scroll-wheel-zoom='true' v-loading='loadingMap'>
							<bm-view style="width: 100%; height:100%; flex: 1"></bm-view>
							<bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none">
							</bm-local-search>
						</baidu-map>
					</div>
				</div>
			</div>
			<div v-else>
				<mobileHome :caseList="caseList" :numLisr="numLisr" :serviceList="serviceList" />
			</div>
		</template>
	</div>
</template>

<script>
	import mobileHome from "@/components/mobile/mobileHome.vue"
	export default {
		name: 'HomeView',
		components: {
			mobileHome
		},
		data() {
			return {
				swiperList: [1, 2, 3, 4],
				serviceList: [{
						img: require("../assets/home1.png"),
						title: "展会服务",
						content: "提供一站式解决方案服务：包括前期策划，展厅设计、运营管理在内的整体能力。",
					},
					{
						img: require("../assets/home2.png"),
						title: "厂商对接",
						content: "在对接厂商当中，我们看到更多的是互帮互助、携手共进的局面，做企业与渠道商之间的桥梁，精准对接。",
					},
					{
						img: require("../assets/home3.png"),
						title: "战略全案赋能",
						content: "升级战略思维和商家经营思维，与商家共创事业；\r\n催生新的增长方式，提升获利能力，为商家创造市场地位。",
					},
					{
						img: require("../assets/home4.png"),
						title: "供应链",
						content: "协助企业完成铺货工作，由品饮汇平台直接对接产品和线下分发渠道。",
					},
					{
						img: require("../assets/home1.png"),
						title: "投融资",
						content: "针对有成长潜能和亮点的企业进行投资支持，协助中小企业形成快速裂变。",
					}
				],
				caseList: [{
						title: "展会",
						content: "强招商，目前以每年春秋两届糖酒会+秋季济南休食展为主",
					},
					{
						title: "新媒体",
						content: "广扩散，用新媒体的武器裂变扩散影响力，包含公众号、短视频、直播、小程序、课程等。",
					},
					{
						title: "论坛",
						content: "以活动的形式，精准地与渠道端链接，包含新势能饮品峰会/“饮力无限”新品大会/饮品渠道大会/中国饮品金露奖颁奖盛典等。",
					}
				],
				numLisr: [{
						num: "1500+",
						eng: "community",
						jieshao: "全国渠道商社群",
						nums: 1500,
						numbers: [],
						currentNumber: 0,
						intervalId: "",
					},
					{
						num: "20万+",
						eng: "distributor",
						jieshao: "覆盖渠道商",
						nums: 200000,
						numbers: [],
						currentNumber: 0,
						intervalId: "",
					},
					{
						num: "5500+",
						eng: "brand",
						jieshao: "服务品牌",
						nums: 5500,
						numbers: [],
						currentNumber: 0,
						intervalId: "",
					}
				],
				act_case: 0,
				timer: null,
				// 地图相关
				loadingMap: true,
				BMap: '',
				map: '',
				geoc: '',
				showMap: false,
				addressKeyword: '',
				pointLngLat: '',
				center: {
					lng: 104.113343,
					lat: 30.641075
				},
				zoom: 18,
				top: 0,
				duration: 3000,
				numTop: 0,
				ismobile: false,
				newslists: [
					// require("../assets/home1.png"),
					// require("../assets/home1.png")
				],
				reviewList: [],
			}
		},
		created() {
			location.href='https://web.pinyinhui.cn/'
			this.ismobile = this.$isMobile()
		},
		mounted() {
			if (!this.ismobile) {
				this.changeCase()
				this.top = this.$refs.titles.offsetTop,
					this.numTop = this.$refs.numberTop.offsetTop
				window.addEventListener('scroll', this.handleScroll)
				setTimeout(() => {
					this.$refs.topContent.style.opacity = 0
				}, 1000)
			}
			this.getList()
		},
		beforeDestroy() {
			// 清除定时器
			this.numLisr.forEach(item => {
				clearInterval(item.intervalId);
			})
			window.removeEventListener('scroll', this.handleScroll); //监听页面滚动事件
		},
		methods: {
			getList() {
				this.$api.reviewList({
					page: this.page,
					limit: 4
				}).then(res => {
					console.log(res, 333);
					if (res.code == 1) {
						//
						res.data.data.forEach((item, index) => {
							if (index == 0) {
								var imgList = item.main_dxjfav.split(',')
								this.newslists = imgList;
							}
							this.reviewList.push(item)
						})
					}
				})
			},
			changeNum() {
				this.numLisr.forEach(item => {
					// 初始化数字数组
					item.numbers = item.currentNumber.toString().split('').map(Number);
					// 启动定时器
					item.intervalId = setInterval(() => {
						const increment = Math.ceil((item.nums - item.currentNumber) / (this.duration /
							10));
						item.currentNumber += increment;
						// 更新数字数组
						item.numbers = item.currentNumber.toString().split('').map(Number);
						if (item.currentNumber >= item.nums) {
							clearInterval(item.intervalId);
						}
					}, 10);
				})
			},
			handleScroll() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				// console.log(scrollTop, '滚动距离')
				// console.log(this.top);
				// console.log(this.numTop);
				if (scrollTop >= this.top) {
					this.changes()
				}
				if (scrollTop >= this.numTop) {
					this.changeNum()
				}
			},
			changes() {
				this.$refs.serviceItem.forEach((item, index) => {
					if (index % 2 == 0) {
						setTimeout(() => {
							item.style.left = 0
						}, 10)
					} else {
						item.style.display = 'flex'
						setTimeout(() => {
							item.style.right = 0
						}, 10)
					}
				})
			},
			toDetail(item) {
				this.$router.push({
					path: '/CaseDetail',
					query: {
						id: item.id
					}
				})
			},

			// 地图初始化
			handler({
				BMap,
				map
			}) {
				// this.mapOpen()
				this.BMap = BMap
				this.map = map
				this.loadingMap = true
				// var geolocation = new BMap.Geolocation()
				this.geoc = new BMap.Geocoder() // 地址解析对象
				var $this = this
				$this.$set($this, 'pointLngLat', {
					lng: 104.113343,
					lat: 30.641075,
				})
				map.enableScrollWheelZoom(false) // 开启鼠标滚轮缩放,默认关闭
				$this.addPoint({
					BMap,
					map
				})
				$this.loadingMap = false
				// 调用百度地图api 中的获取当前位置接口
				// geolocation.getCurrentPosition(function(r) {
				// 	let myGeo = new BMap.Geocoder()
				// 	myGeo.getLocation(new BMap.Point(r.point.lng, r.point.lat), function(result) {
				// 		if (result) {
				// 			console.log(result,333);
				// 			$this.loadingMap = false
				// $this.$set($this, 'pointLngLat', {
				// 	lng: 104.113343,
				// 	lat: 30.641075,
				// })
				// 			map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放,默认关闭
				// 			$this.addPoint({
				// 				BMap,
				// 				map
				// 			})
				// 		}
				// 	})
				// })
			},
			// 添加点位
			addPoint({
				BMap,
				map
			}) {
				map.clearOverlays()
				var myIcon = new BMap.Icon(require("../assets/mapsico.png"), new BMap.Size(50, 45));
				var point = new BMap.Point(this.pointLngLat.lng, this.pointLngLat.lat)
				let zoom = map.getZoom()
				console.log('zoom:' + zoom)
				setTimeout(() => {
					map.centerAndZoom(point, zoom)
				}, 0)
				var marker = new BMap.Marker(point, {
					icon: myIcon
				}) // 创建标注
				map.addOverlay(marker) // 将标注添加到地图中

				var opts = {
					position: point,
				};
				var label = new BMap.Label(
					'<div><p>联系电话：86-19102811607</p><p>地址：四川省成都市锦江区泰和·国际财富中心7栋702号</p></div>',
					opts);
				label.setStyle({
					color: '#fff',
					borderRadius: '5px',
					border: "0",
					background: "rgba(0,0,0,0.5)",
					padding: '2px 10px',
					marginTop: "0px"
				});
				map.addOverlay(label);
			},
			// 地图相关
			mapOpen() {
				this.top = this.getScrollTop()
				if (this.top) {
					this.setScrollTop(0)
				}
			},
			// 关闭地图后调用
			mapClose() {
				this.setScrollTop(this.top)
				this.top = 0
				this.showMap = false
			},
			getScrollTop() {
				let scrollTop = 0
				if (document.documentElement && document.documentElement.scrollTop) {
					scrollTop = document.documentElement.scrollTop
				} else if (document.body) {
					scrollTop = document.body.scrollTop
				}
				return scrollTop
			},
			setScrollTop(top) {
				if (!isNaN(top)) {
					if (document.documentElement && document.documentElement.scrollTop !== undefined) {
						document.documentElement.scrollTop = top
					} else if (document.body) {
						document.body.scrollTop = top
					}
				}
			},
			// 案例展示鼠标移入
			inCase(indexs) {
				clearInterval(this.timer)
				this.$refs.caseItem.forEach((item, index) => {
					if (index == indexs) {
						this.$refs.caseItem[index].style.borderTop = "4px solid #CB2339"
						this.$refs.caseItem[index].style.color = "#fff"
					} else {
						this.$refs.caseItem[index].style.borderTop = "4px solid #fff"
						this.$refs.caseItem[index].style.color = "rgba(255, 255, 255, 0.70)"
					}
				})
				this.act_case = indexs
			},
			// 案例展示自动滚动
			changeCase() {
				this.$refs.caseItem[this.act_case].style.borderTop = "4px solid #CB2339"
				this.$refs.caseItem[this.act_case].style.color = "#fff"
				this.timer = setInterval(() => {
					this.act_case++
					if (this.act_case > 2) {
						this.act_case = 0
					}
					this.$refs.caseItem.forEach((item, index) => {
						if (index == this.act_case) {
							this.$refs.caseItem[index].style.borderTop = "4px solid #CB2339"
							this.$refs.caseItem[index].style.color = "#fff"
						} else {
							this.$refs.caseItem[index].style.borderTop = "4px solid #fff"
							this.$refs.caseItem[index].style.color = "rgba(255, 255, 255, 0.70)"
						}
					})
				}, 1000)
			},
		}
	}
</script>

<style lang="less">
	@keyframes number-roll {
		0% {
			transform: translateY(0);
		}

		50% {
			transform: translateY(-20px);
		}

		100% {
			transform: translateY(0);
		}
	}

	div {
		margin: 0;
		padding: 0;
	}

	.flexcol {
		display: flex;
		align-items: center;
		flex-direction: column;

	}

	.banner {
		width: 100%;
		height: 67.5rem;
	}

	.sec1 {
		width: 100%;
		padding: 10.625rem 0;
		background: #FFFFFF;
	}

	.title {
		font-size: 3.125rem;
		color: #333333;
		font-weight: bold;
	}

	.texts {
		width: 52.3125rem;
		font-size: 1.125rem;
		color: #666666;
		text-align: center;
		margin-top: 0.6875rem;
		line-height: 2.1875rem;
		margin-bottom: 5.5rem;
	}

	.service {
		width: 100%;
		background: #25272A;
		padding: 10.625rem 22.5rem 10.625rem;
		box-sizing: border-box;
		color: #fff;

		// height: 65.0625rem;
		.service_list {
			width: 75rem;
			padding-top: 2.375rem;
			box-sizing: border-box;
			margin: 0;
			position: relative;

			.service_saa {
				direction: rtl;

				.service_item {
					right: -100rem;
					display: none;
					// &:nth-child(2n) {
					// 	right: -100rem;
					// }
				}
			}

			.service_item {
				width: 63.5rem;
				display: flex;
				color: #fff;
				margin-top: 3.625rem;
				transition: all linear .5s;
				position: relative;

				img {
					width: 17.875rem;
					height: 18.125rem;
					margin-right: 3.25rem;
				}

				&:nth-child(2n-1) {
					left: -86rem;
				}
			}

			.service_content {
				height: 18.125rem;
				padding-top: 4.1875rem;
				box-sizing: border-box;
				text-align: left;

				&:nth-child(2n) {
					direction: ltr;
				}

				div {
					&:nth-of-type(1) {
						font-size: 2.5rem;
						font-weight: 700;
						line-height: 3.625rem;
					}

					&:nth-of-type(2) {
						width: 42.375rem;
						font-size: 1.125rem;
						line-height: 2.1875rem;
						margin-top: 1.25rem;
					}
				}
			}
		}
	}

	.news {
		padding-top: 3rem;
		width: 70%;
		margin: 0 auto;
		font-size: 3rem;
		color: #666666;
		line-height: 2.1875rem;

		.news_shows {
			padding-top: 3rem;

			.lefts {
				width: 30%;
				height: 266px;
				float: left;
			}

			.right {
				width: 70%;
				height: 266px;
				float: left;
				font-size: 1.2rem;
				text-align: left;

				div {
					margin-left: 4rem;
					border-bottom: 1px solid #D8D8D8;
					padding: 1rem;
				}

				a {
					text-decoration: none;
				}

				.one {

					line-height: 35px;
					font-size: 1.5rem;
					font-weight: 600;
					-webkit-background-clip: text;

					a {
						color: #CB2339;
					}
				}

				.tetxs {
					font-size: 1.2rem;

					line-height: 26px;
					-webkit-background-clip: text;

					a {
						color: #999;
					}
				}

				.fouver {
					border: 0
				}
			}
		}
	}

	.case {
		width: 100%;
		height: 54.375rem;
		background-image: url("../assets/case_bg.png");
		background-size: 100% 100%;
		padding-top: 11.75rem;
		padding-left: 22.5rem;
		box-sizing: border-box;

		.case_title {
			width: 12.5rem;
			font-size: 3.125rem;
			font-weight: 700;
			color: #FFFFFF;
			// line-height: 72px;
		}

		.case_list {
			margin-top: 13.5rem;
			margin-left: 18.75rem;
			display: flex;
			margin-bottom: 0.875rem;

			.case_list_title {
				font-size: 2.25rem;
				color: #fff;
				font-weight: 700;
			}

			.case_list_content {
				width: 40rem;
				font-size: 1.5rem;
				color: #FFFFFF;
				line-height: 2.1875rem;
				margin: 0.875rem 2.0625rem 0;
			}

			.case_list_img {
				width: 3.125rem;
				height: 3.125rem;
			}
		}

		.case_line {
			width: 56.25rem;
			margin-top: 0.875rem;
			margin-left: 18.75rem;
			display: flex;

			.case_item {
				width: 33%;
				text-align: center;
				border-top: 4px solid #fff;
				padding-top: 1.25rem;
				font-size: 1.5rem;
				color: rgba(255, 255, 255, 0.70);
			}

			// .case_item:hover{
			// 	color: #fff;
			// 	border-top: 4px solid #CB2339;
			// }
		}
	}

	.maps {
		width: 65rem;
		height: 30rem;
	}

	.BMap_cpyCtrl {
		display: none;
	}

	.anchorBL {
		display: none
	}

	.home_top {
		width: 100%;
		height: 67.5rem;
		position: relative;
		padding: 0;

		.top_content {
			width: 100%;
			height: 100%;
			justify-content: center;
			position: absolute;
			color: #fff;
			z-index: 1;
			top: 0;
			box-sizing: border-box;
			opacity: 0;
			transition: all linear 2s;

			div {
				&:nth-of-type(1) {
					font-size: 6.875rem;
					font-weight: 900;
					color: rgba(0, 0, 0, 0);
					line-height: 10rem;
					-webkit-text-stroke: 1px #FFFFFF;
					letter-spacing: 6px;
				}

				&:nth-of-type(2) {
					font-size: 6.875rem;
					font-weight: 900;
					line-height: 10rem;
					margin-top: -2.75rem;
					letter-spacing: 6px;
				}

				&:nth-of-type(3) {
					width: 3.75rem;
					height: 2px;
					background: #FFFFFF;
					margin-top: 0.625rem;
				}

				&:nth-of-type(4) {
					font-size: 2.25rem;
					font-weight: 700;
					line-height: 3.25rem;
					margin-top: 2.5rem;
				}
			}
		}

		video {
			width: 100%;
			height: 100%;
			object-fit: contain;
			border: none;
		}

		video:focus {
			outline: none;
		}

		video:after {
			border: none;
		}
	}

	.home_map {
		background-image: url("../assets/home_map.png");
		background-size: 100% 100%;
		width: 100%;
		height: 56.25rem;
		padding-top: 14.8125rem;
		box-sizing: border-box;
		margin-top: -10px;

		.numbs {
			width: 60.625rem;
			display: flex;
			justify-content: space-between;

			.lines {
				width: 0px;
				height: 12.5rem;
				opacity: 1;
				border: 1px solid #999999;
				margin-left: 8.125rem;
			}

			.numbs_item {
				// width: 33%;
				display: flex;
				justify-content: space-between;
			}

			.num {
				font-size: 4rem;
				color: #333;
				font-weight: 700;
				animation: number-roll 0.5s ease-in-out;
				display: flex;
				align-items: center;
			}

			.eng {
				color: #666666;
				font-size: 1.25rem;
				margin-top: 0.625rem;
				margin-bottom: 0.625rem;
				font-weight: 700;
			}

			.jieshao {
				font-size: 1.5rem;
				color: #666;
				font-weight: 700;
			}

			.border_line {
				width: 8.1875rem;
				height: 0.625rem;
				background: #CB2339;
				margin: auto;
				margin-top: -0.625rem;
			}
		}


		.title {
			font-size: 3.125rem;
			color: #333333;
			font-weight: 700;
			line-height: 4.5rem;
		}

		.texts {
			width: 57.125rem;
			font-size: 1.125rem;
			color: #666666;
			// text-align: center;
			font-weight: 700;
			margin-top: 0.625rem;
			line-height: 2.1875rem;
			margin-bottom: 3.75rem;

			p {
				text-align: left;
				text-indent: 2em;
			}
		}
	}
</style>