<template>
	<div id="app">
		<template>
			<nav class="nav" v-if="!isMobile">
				<div>
					<img class="logo_img" src="@/assets/web_logo.png" alt="">
				</div>
				<div class="tabs">
					<router-link to="/">首页</router-link>
					<router-link to="/about">关于我们</router-link>
					<router-link to="/service">服务</router-link>
					<router-link to="/review">新闻动态</router-link>
					<router-link to="/anli">案例展示</router-link>
					<router-link to="/contact">联系我们</router-link>
				</div>
			</nav>
			<div v-if="isMobile" class="phone" slot="title">
				<img class="logo_img" src="@/assets/web_logo.png" alt="">
				<template>
					<img @click="drawer=true" src="../src/assets/phone_menu.png" class="phone_menu" alt="">
				</template>
			</div>
		</template>
		<router-view />
		<template>
			<div v-if="isMobile"  >
				<el-collapse  v-model="activeNames" @change="handleChange">
					<el-collapse-item  v-for="(item,index) in footList"  :key="index" :name="item.name">
						<template slot="title" >
							<span style="margin-left: 15px;color: #333333;font-size: 14px;">{{item.name}}</span>
						</template>
						<div class="foot_mo_main">
							<router-link v-for="(items,indexs) in item.list" :key="indexs" to="/contact">{{items.name}}
							</router-link>
						</div>
					</el-collapse-item>
				</el-collapse>
				<div style="text-align:center;padding:10px 15px 20px;font-size: 12px;color: #666666;">
						<a href="https://beian.miit.gov.cn/" title="成都创智公关策划有限公司" target="_blank" style="text-decoration: none;color: #999999;">成都创智公关策划有限公司@蜀ICP备2021031193号</a>
				</div>
			</div>
			<div v-else class="foot">
				<div class="foot_main">
					<img src="../src/assets/foot_logo.png" alt="">
					<div class="line">

					</div>
					<div class="foot_list" v-for="(item,index) in footList" :key="index">
						<div class="list_name">
							{{item.name}}
						</div>
						<router-link v-for="(items,indexs) in item.list" :key="indexs" to="/contact">{{items.name}}
						</router-link>
					</div>
				</div>
				<div class="foot_texts">
					<div>
						<a href="https://beian.miit.gov.cn/" title="成都创智公关策划有限公司" target="_blank">成都创智公关策划有限公司@蜀ICP备2021031193号</a>
					</div>
				</div>
			</div>
		</template>
		<!-- 客服 -->
		<div v-if="isMobile">
			
		</div>
		<div v-else>
			<Kefu></Kefu>
		</div>
		
		<!-- 手机菜单 -->
		<el-drawer :visible.sync="drawer" size="446" :direction="direction" :show-close="false" :with-header="true">
			<div class="phone" style="position: relative;z-index: 0;" slot="title">
				<img class="logo_img" src="@/assets/web_logo.png" alt="">
				<template>
					<img v-if="!drawer" @click="drawer=true" src="../src/assets/phone_menu.png" class="phone_menu"
						alt="">
					<img v-else @click="drawer=false" src="../src/assets/close_menu.png" class="phone_menu" alt="">
				</template>
			</div>
			<ul class="phone_ul">
				<li>
					<router-link to="/">首页</router-link>
				</li>
				<li>
					<router-link to="/about">关于我们</router-link>
				</li>
				<li>
					<router-link to="/service">服务</router-link>
				</li>
				<li>
					<router-link to="/review">新闻动态</router-link>
				</li>
				<li>
					<router-link to="/anli">案例展示</router-link>
				</li>
				
				<li>
					<router-link to="/contact">联系我们</router-link>
				</li>
			</ul>
		</el-drawer>
	</div>
</template>

<script>
	import Kefu from "@/components/KefuFix.vue"
	export default {
		components: {
			Kefu
		},
		data() {
			return {
				drawer: false,
				isMobile: false,
				direction: 'ttb',
				footList: [{
						name: "首页",
						list: [{
								name: "关于我们"
							},
							{
								name: "产品与服务"
							},
							{
								name: "案例展示"
							},
							{
								name: "地址"
							}
						]
					},
					{
						name: "关于我们",
						list: [{
							name: "企业文化"
						}, {
							name: "公司荣耀"
						}, {
							name: "发展历程"
						}]
					},
					{
						name: "服务",
						list: [{
							name: "IPD咨询"
						}, {
							name: "新媒体推广"
						}, {
							name: "展会"
						},{
							name: "渠道链接"
						},]
					},
					{
						name: "新闻动态",
						list: [{
								name: "重点新闻"
							},
							
						]
					},
					{
						name: "案例展示",
						list: [{
								name: "重点案例"
							},
							
						]
					},
					{
						name: "联系我们",
						list: [{
								name: "商务合作"
							},
							{
								name: "招贤纳士"
							},
							{
								name: "更多动态"
							}
						]
					}
				],
				activeNames: ['1']
			}
		},
		mounted() {
			this.isMobile = this.$isMobile()
			this.$router.beforeEach((to, from, next) => {
				this.drawer = false
				next()
			})
		},
	}
</script>

<style scoped>
	/deep/.el-drawer__header {
		padding: 0;
		margin: 0;
	}
	/deep/.el-collapse-item__content{
		padding-top: 10px;
		padding-bottom: 10px;
	}
</style>
<style lang="less">
	// #app {
	// 	font-family: Avenir, Helvetica, Arial, sans-serif;
	// 	-webkit-font-smoothing: antialiased;
	// 	-moz-osx-font-smoothing: grayscale;
	// 	text-align: center;
	// 	color: #2c3e50;
	// }
	body {
		margin: 0;
	}

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		list-style: none;
	}

	nav {
		width: 100%;
		height: 5.625rem;
		background: rgba(255, 255, 255, .5);
		position: fixed;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: space-around;
		z-index: 1000;

		.logo_img {
			width: 10.125rem;
			height: 2.125rem;
		}

		.tabs {
			width: 40%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		a {
			font-size: 1.25rem;
			color: #333333;
			text-decoration: none;

			&.router-link-exact-active {
				font-size: 1.375rem;
				color: #333333;
				// font-size: 1.875rem;
				font-weight: bold;
			}
		}
	}
	.foot_mo_main{
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-left: 14px;
		box-sizing: border-box;
		a {
			font-size: 12px;
			font-weight: 400;
			color: #666666;
			line-height: 17px;
			text-decoration: none;
			margin-bottom: 5px;
		}
	}
	.foot {
		width: 100%;
		background-color: #f6f6f6;
		display: flex;
		align-items: center;
		flex-direction: column;

		.foot_main {
			width: 75rem;
			border-top: 1px solid #D8D8D8;
			padding-top: 4.375rem;
			display: flex;
			// align-items: center;
			justify-content: space-between;
			box-sizing: border-box;

			img {
				width: 9.6875rem;
				height: 6.25rem;
			}

			.line {
				height: 10.625rem;
				opacity: 1;
				border: 1px solid #D8D8D8;
			}

			.foot_list {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.list_name {
				font-size: 1rem;
				color: #333333;
				line-height: 1.5rem;
			}

			a {
				font-size: 1rem;
				color: #666666;
				line-height: 1.5rem;
				text-decoration: none;
				margin-top: 0.625rem;
			}
		}

		.foot_texts {
			width: 75rem;
			border-top: 1px solid #D8D8D8;
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 0.875rem;
			color: #999999;
			line-height: 1rem;
			padding-bottom: 6.25rem;
			padding-top: 1.875rem;
			box-sizing: border-box;
			margin-top: 4.375rem;
			a{
				text-decoration: none;
				color: #999999;
				}
		}
	}

	.flexCenter {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.flexCol {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.phone {
		width: 100%;
		height: 40px;
		background: #F6F6F6;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 15px;
		box-sizing: border-box;
		position: fixed;
		top: 0;
		z-index: 1000;

		.logo_img {
			width: 71px;
			height: 15px;
		}

		.phone_menu {
			width: 16px;
			height: 16px;
		}
	}

	.phone_ul {
		width: 100%;
		padding: 0 15px;
		box-sizing: border-box;

		li {
			padding: 20px 0;
			border-bottom: 1px solid #D8D8D8;
			box-sizing: border-box;

			a {
				font-size: 14px;
				font-weight: 700;
				color: #333333;
				line-height: 20px;
				text-decoration: none;
			}
		}
	}
</style>
