import Axios from "axios";
let service = Axios.create({
	baseURL: "https://crm.pinyinhui.cn/addons/qingdong/", 
	// baseURL: 'http://49.234.41.34:8010/api/',
	timeout: 10000,
});
// Axios.defaults.baseURL ='/api';
service.interceptors.request.use(
	config => {
		return config;
	},
	err => {
		console.log(err);
		Promise.reject(err);
	}
);
service.interceptors.response.use(
	response => {
		// response.data["data"] = JSON.parse(data)
		return response.data;
	},
	err => {
		// console.log(err.response);
		if (err.response) {
			if (err.response.status == 500) {
				return { data: { code: 500 } }
			}
		}
		return err;
	}
);

export default { service };
