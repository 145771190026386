import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CaseDetail from "../views/CaseDetail.vue"
// import Review from "../views/reviewView.vue"


Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: '首页',
		component: HomeView
	},
	{
		path: '/about',
		name: '关于我们',
		component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
	},
	{
		path: '/service',
		name: '服务',
		component: () => import('../views/serviceView.vue')
	},
	{
		path: '/review',
		name: '新闻动态',
		component: () => import('../views/reviewView.vue'),
		// children:[
		// 	{
		// 		path: '/review/caseDetail',
		// 		name: '案例详情',
		// 		component: () => import('../views/CaseDetail.vue'),
		// 	}
		// ]
	},
	{
		path: '/caseDetail',
		name: '案例详情',
		component: CaseDetail,
	},
	{
		path: '/anli',
		name: '案例展示',
		component: () => import('../views/AnLiView.vue'),
	},
	// 展台
	{
		path: '/zhantai',
		name: '展台详情',
		component: () => import('../views/zhan-tai.vue'),
	},
	{
		path: '/contact',
		name: '联系我们',
		component: () => import('../views/contactView.vue')
	}
]

const router = new VueRouter({
	routes
})

export default router