<template>
	<div>
		<div  class="kefu">
			<img @click="opKefu" class="kefu_img" src="../assets/kefu.png" alt="">
			<template>
				<img v-if="top>=1000"  @click="backTop" class="back_img" src="../assets/backTop.png" alt="">
				<div v-else class="back_img">
					
				</div>
			</template>
		</div>
		<div v-if="dialogVisible" class="kefu_detail">
			<img  class="detail_img" src="../assets/kefu_detail.jpg" alt="">
			<img  @click="opKefu" class="close_img" src="../assets/close_kefu.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				top: 0,
				dialogVisible:false
			}
		},
		mounted() {
			setInterval(() => {
				this.top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			}, 1000)
		},
		methods: {
			opKefu() {
				this.dialogVisible=!this.dialogVisible
			},
			backTop() {
				// 设置滚动行为改为平滑的滚动
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			}
		}
	}
</script>

<style lang="less">
	.kefu {
		position: fixed;
		right: 2.5rem;
		bottom: 13.125rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		z-index: 10;
		.kefu_img {
			width: 4.1875rem;
			height: 4.9375rem;
			cursor: pointer;
		}

		.back_img {
			width: 2.5rem;
			height: 2.5rem;
			margin-top: 0.625rem;
			cursor: pointer;
		}
	}
	.kefu_detail{
		position: fixed;
		right: 8.75rem;
		bottom: 9.375rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		z-index: 10;
		.detail_img{
			width: 14.375rem;
			height: 18.125rem;
		}
		.close_img{
			width: 1.9375rem;
			height: 1.9375rem;
			position: absolute;
			top: -1.9375rem;
			right: -1.9375rem;
			cursor: pointer;
		}
	}
	.kefu_detail1{
		position: absolute;
		left: 15rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		z-index: 10;
		bottom: 0rem;
		.detail_img{
			width: 14.375rem;
			height: 18.125rem;
		}
		.close_img{
			width: 1.9375rem;
			height: 1.9375rem;
			position: absolute;
			top: -1.9375rem;
			right: -1.9375rem;
			cursor: pointer;
		}
	}
</style>
